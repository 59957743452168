@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400&family=Quicksand:wght@300;400;500&display=swap);
* {box-sizing: border-box;}


body,
html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

li,
a,
button {
    font-family: 'Quicksand', sans-serif;

}


.navbar-logo {
    position: absolute;
    left: 6vw;
    cursor: pointer;
}

.nav-links {
    position: relative;
    left: 72.3vw;
    list-style-type: none;
    display: none;


}


.nav-links li {
    display: inline-block;
    padding: 0 1rem;
}

.nav-links a {
    color: rgb(206, 203, 203);
    font-size: 1rem;
    text-transform: capitalize;

    font-weight: bold;
    text-decoration: none;

}


.nav-links li a {
    transition: all 0.3s ease 0s;
}


.nav-links li a:hover {
    color: white;
}


header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8vh;
    padding: 1% 5%;
    background: rgba(235, 84, 36, 0.69);
}


.column {
    display: flex;
    flex-direction: column;
    /* float: left;
    width: 50%;
    padding: 10px; */
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.hero-text {
    position: relative;
    top: 28vh;
    left: 6vw;
    width: 80vw;
    /* font: 'Bree Serif'; */
    color: white;
    text-align: left;
    font-family: "Bree Serif";

    font-size: 3rem;
    line-height: 3rem;

    color: #FFFFFF;
    opacity: 0.92;
}

.get-started-mobile {
    position: relative;
    top: 35vh;
    left: 6vw;
    background: transparent linear-gradient(180deg, #EB5424 0%, #FC6D3FFC 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #F55C2B;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.2rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: 40vw;

}

.what-is-battr {
    position: relative;
    top: 30vh;
    left: 6vw;
    width: 80vw;

    color: white;
    text-align: left;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 1.3rem;

    color: #FFFFFF;
    opacity: 0.92;

}

.how-battr-works-heading {
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
    
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    color: #F25F30;
    opacity: 0.97;
}



/* For tablet */
/* @media only screen and (min-width: 768px) {
    .nav-links {
        position: relative;
        left: 65vw;
        list-style-type: none;
        display: block;

    }

    .hero-text {
        position: relative;
        top: 25vh;
        left: 6vw;
        width: 50vw; */
        /* font: 'Bree Serif'; */
        /* color: white;
        text-align: left;
        font-family: "Bree Serif";
        font-size: 4rem;
        line-height: 4.5rem;

        color: #FFFFFF;
        opacity: 0.92;
    }
} */





/* @media only screen and (max-width: 600px) {
    .get-started-mobile {
        display: block;
        position: relative;
        top: 35vh;
        left: 6vw;
        background: transparent linear-gradient(180deg, #EB5424 0%, #FC6D3FFC 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #F55C2B;
        border-radius: 4px;
        opacity: 1;
        text-align: center;
        font-family: 'Quicksand', sans-serif;
        font-size: 1rem;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
        padding: 0.5rem 2rem;

    }
} */





.form-wrapper {
    display: none;
    position: relative;
    top: 13vh;
    left: 9.5vw;
    width: 30vw;
    height: 37rem;
    padding: 5rem 2.5rem 5rem 2.5rem;
    background-color: white;
    opacity: 0.95;

}

/* @media only screen and (max-width: 600px) {
    .form-wrapper {
        display: none;
    }
} */


.terms-and-conditions {
    font-size: 14px;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    opacity: 0.63;
    letter-spacing: 0.4px;
}



.policy-disclaimer {
    background-color: white;
    color: #EB5424;
    cursor: pointer;
}

.form-header {
    position: absolute;
    top: 1rem;
    font-size: 1.7rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    color: #EB5424;


}

.get-started-form {

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

}

input[type=text] {
    padding: 13px 15px;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    outline: none !important;
    border: 1px solid rgba(30, 30, 30, 0.44);
    opacity: 0.95;


}

input[type=text]::-webkit-input-placeholder {
    opacity: 0.70;
}

input[type=text]:-ms-input-placeholder {
    opacity: 0.70;
}

input[type=text]::-ms-input-placeholder {
    opacity: 0.70;
}

input[type=text]::placeholder {
    opacity: 0.70;
}


input[type=text]:focus {
    border: 2px solid #EB5424 !important;

}








.hero-image {
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(to right, rgba(235, 84, 36, 0.6), rgba(118, 42, 18, 0.4)), url(/static/media/theBattrHeroImage.36e98c4e.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;



}


.submit-button {
    background: transparent linear-gradient(180deg, #EB5424 0%, #FC6D3FFC 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #F55C2B;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    height: 2.5rem;
    cursor: pointer;
}


.submit-button:active,
.submit-button:hover,
.submit-button:focus {
    outline: 0;
    border: 2px solid #c53d14 !important;
}










.container {
    position: absolute;

    width: 100vw
}








.topnav {
    width: 100%;
    background: linear-gradient(rgba(208, 86, 47, 0.8), rgba(238, 137, 107, 0.3));

}

.topnav a {

    float: right;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.battr-nav-button {

    border: none;
    border-radius: 25px;

    background-color: white;
    color: #EB5424;
    text-transform: lowercase;
    opacity: 1;
}


.joinNow {
    background-color: white;
    padding: 0.5rem !important;
    color: #EB5424 !important;
    text-transform: lowercase;
    border-radius: 12px;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    box-shadow: #131212 2px 2px;



}

.aboutUs {
    margin-right: 1rem;
    background-color: transparent;
    padding: 0.5rem !important;
    color: white !important;
    text-transform: lowercase;
    border-radius: 12px;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;

}




.new-customers-row {
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.meet-new-customers-textbox {
    /* heading */
    width: 100%;

    font-family: 'Quicksand', sans-serif;
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
    padding: 1rem;
    color: #F25F30;


}

.meet-new-customers-textbox p {
    color: #707070;
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 0.81px;
    width: 100%;
    font-size: 1.2rem;
}

.meet-new-customers-imagebox {
    display: none;
    position: relative;
    top: 5vh;
    left: 3vw;
    width: 37vw;
    height: 42vh;

    background: url(/static/media/BatterBakerGirl-Scene1.f3c43a4e.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


}

/* @media only screen and (max-width: 600px) {
    .meet-new-customers-imagebox {
        display: none;


    }

} */

/* .meet-new-customers-imagebox img {
    width: auto;
    max-width: 35rem;
    height: auto;

} */





.heading {
    margin: 0px;
    padding: 0px;
    color: #504848cc;
    font-size: 2.5rem;
    font-family: 'Bree Serif', sans-serif;
    font-weight: 200 !important;
    font-style: italic;

}


.how-battr-works-for-partners-Boxes-Container {
    display: flex;
    flex-direction: column
}


.box1 {
    display: block;
    margin: 1rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem;
    background-color: rgba(242, 95, 48, 0.2);

}

.box1 h1 {
    font-weight: bolder;
    color: transparent;
    font-family: 'Quicksand';
    font-size: 5rem;
    -webkit-text-stroke-width: 0.3px;
    -webkit-text-stroke-color: #EB5424;
    opacity: 1;
}

.box2 {

    background-color: rgba(139, 233, 217, 0.3);
    display: block;
    margin: 1rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem;
}

.box2 h1 {
    color: transparent;
    font-family: 'Quicksand';
    font-size: 5rem;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #EB5424;
    opacity: 0.7;
}

.box3 {
    background-color: rgba(255, 255, 0, 0.2);
    display: block;
    margin: 1rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem;


}

.box3 h1 {
    color: transparent;
    font-family: 'Quicksand';
    font-size: 5rem;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #ec4912;
    opacity: 0.7;
}


.common-box-text p,
b {
    opacity: 1;
    font-family: 'Quicksand';
    letter-spacing: 0.2px;
    color: rgba(242, 95, 48, 1);
    font-size: 1.2rem;
}




.roww {
    display: flex;
    flex-direction: row;

    min-height: 20vh;

}

.orange-bg {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #F66536FC;
}

.section2 {
    width: 100%;

}


.section3 {
    /* padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem; */
    height: 100vh;
}


.build-your-reputation-textbox {
    
    padding: 1rem;
}

.build-your-reputation-textbox p {
   
    text-align: center;
    font: normal normal normal 2rem Quicksand;
    font-size: 1.2rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 0.8;
    min-width: 2rem;
    width: 100%;

}



.build-your-reputation-imagebox {

    margin: 2rem;
    height: 50vh;

    background: url(/static/media/BatterBakerGirl-Scene2.d64e548b.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


}


.mobile-image-box {
    display: none;
    position: relative;
    top: 5vh;
    left: 10vw;
    width: 30vw;
    height: 39vh;


    background: url(/static/media/Mobile-Shot.51d9b59a.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


}


.mobile-get-started-textbox h1 {
    
    width: 100%;
    margin-top: 2rem;
    padding: 1rem;
    font-family: 'Quicksand', sans-serif;
    text-align: center;
    color: #F25F30;
    opacity: 0.97;

}

.mobile-get-started-textbox p {
    
    text-align: left;
    font-size: 1.2rem;
    font-family: Quicksand;
    letter-spacing: 0.9px;
    color: #707070;
    opacity: 0.8;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    white-space:normal;
    text-overflow: ellipsis;
    vertical-align: top;

}

.join-us-button {
   
    background: transparent linear-gradient(180deg, #EB5424 0%, #FC6D3FFC 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #F55C2B;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 1rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    cursor: pointer;
    padding: 0.5rem 1rem;
    width: 30vw;
    margin-left: 2rem;
    margin-top: 1rem;
    z-index: 10000;


}

.section4 {

    min-height: 50vh;
}


.footer-links-row {
    position: relative;
    top: 30vh;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    height: 10vh;
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.footer-links-row p {
    font-size: 1rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #EC693F;
    opacity: 1;
    cursor: pointer;

}

footer {
    position: relative;
    top: 30vh;
    background: #F05C2D 0% 0% no-repeat padding-box;
    min-height: 10vh;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


}

footer p {

    /* position: relative;
    top: 4vh; */

    color: #FFFFFF;
    opacity: 0.9;
    font: normal normal 300 21px/42px Quicksand;
}



@media only screen and (min-width : 768px){
   
    .what-is-battr {
        position: relative;
        top: 30vh;
        left: 6vw;
        width: 70vw;
    
        color: white;
        text-align: left;
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
    
        color: #FFFFFF;
        opacity: 0.92;
    
    }

    .hero-text {
        position: relative;
        top: 28vh;
        left: 6vw;
        width: 80vw;
        /* font: 'Bree Serif'; */
        color: white;
        text-align: left;
        font-family: "Bree Serif";
    
        font-size: 3.2rem;
        line-height: 3.2rem;
    
        color: #FFFFFF;
        opacity: 0.92;
    }
    .get-started-mobile {
        position: relative;
        top: 35vh;
        left: 6vw;
        background: transparent linear-gradient(180deg, #EB5424 0%, #FC6D3FFC 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #F55C2B;
        border-radius: 4px;
        opacity: 1;
        text-align: center;
        font-family: 'Quicksand', sans-serif;
        font-size: 1.4rem;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
        padding: 0.5rem 1rem;
        width: 30vw;
    
    }
    .common-box-text p,b{
        width: 80vw;
    }


    .build-your-reputation-imagebox{
        margin: 4rem;
        margin-bottom: 2rem;
        /* margin-top: 5rem; */
    }
    .section4 {

        min-height: 39vh;
    }

    .footer-links-row {
        position: relative;
        top: 10vh;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        height: 10vh;
        text-align: center;
    
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    footer {
        position: relative;
        top: 10vh;
        background: #F05C2D 0% 0% no-repeat padding-box;
        min-height: 10vh;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    
    
    }


    

}


@media only screen and (min-width : 1000px){

   
    .hero-text {
        position: relative;
        top: 35vh;
        left: 6vw;
        width: 35vw;
        /* font: 'Bree Serif'; */
        color: white;
        text-align: left;
        font-family: "Bree Serif";
    
        font-size: 3.2rem;
        line-height: 3.2rem;
    
        color: #FFFFFF;
        opacity: 0.92;
    }
    .what-is-battr {
        position: relative;
        top: 38vh;
        left: 6vw;
        width: 30vw;
    
        color: white;
        text-align: left;
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
    
        color: #FFFFFF;
        opacity: 0.92;
    
    }

    .get-started-mobile {
        position: relative;
        top: 41vh;
        left: 6vw;
        background: transparent linear-gradient(180deg, #EB5424 0%, #FC6D3FFC 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #F55C2B;
        border-radius: 4px;
        opacity: 1;
        text-align: center;
        font-family: 'Quicksand', sans-serif;
        font-size: 1.4rem;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
        padding: 0.5rem 1rem;
        width: 15vw;
    
    }

    .hero-image {
        min-height: 100vh;
        width: 100%;
        background: linear-gradient(to right, rgba(235, 84, 36, 0.3), rgba(118, 42, 18, 0.4)), url(/static/media/theBattrHeroImage.36e98c4e.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    
    
    
    }

    .section2 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 3rem;
    }

    .meet-new-customers-textbox {
        width: 30vw;
        font-family: 'Quicksand', sans-serif;
        text-align: left;
        font-size: 1.4rem;
        margin-top: 7rem;
        padding: 0;
        color: #F25F30;
        margin-left: 6vw;
    }

    .meet-new-customers-textbox p {
        
        font-size: 1.4rem;
    }

    .meet-new-customers-imagebox {
        display: block;
        position: relative;
        top: 5vh;
        left: 19vw;
        width: 35vw;
        height: 42vh;
    
        background: url(/static/media/BatterBakerGirl-Scene1.f3c43a4e.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    
    
    }
    .how-battr-works-for-partners-Boxes-Container {
        display: flex;
        flex-direction: row;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    .how-battr-works-heading {
        width: 50vw;
        margin-top: 7rem;
        margin-left: 6vw;
        padding: 0;
        
        font-family: 'Quicksand', sans-serif;
        text-align: left;
        color: #F25F30;
        opacity: 0.97;
    }

    .common-box-text p,b{
        width: 22vw;
    }

    .box1 {
        display: block;
        margin: 1rem;
        margin-left: 6vw;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 25vw;
        padding: 2rem;
        background-color: rgba(242, 95, 48, 0.2);
    
    }

    .box2 {
        background-color: rgba(139, 233, 217, 0.3);
        display: block;
        width: 25vw;
        margin: 1rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2rem;
    }
    .box2 p {
        width: 15vw;
    }

    .box3 {
        background-color: rgba(255, 255, 0, 0.2);
        display: block;
        width: 25vw;
        margin: 1rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2rem;
    
    
    }

    .section3 {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 120vh;
        
    }

    .orange-bg {
        display: flex;
        flex-direction: row-reverse;
        
        width: 100%;
        height: auto;
        background-color: #F66536FC;
    }

    .build-your-reputation-imagebox {

        margin: 5rem;
        margin-top: 5rem;
        margin-right: 9vw;
        height: 50vh;
        width: 50vw;
    
        background: url(/static/media/BatterBakerGirl-Scene2.d64e548b.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    
    
    }

    .build-your-reputation-textbox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        /* margin-left: 6vw; */
        width: 50vw;
    
    }

    .build-your-reputation-textbox p {
        
   
        text-align: left;
        font: normal normal normal 2rem Quicksand;
        font-size: 2rem;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.8;
        min-width: 2rem;
        width: 30vw;
    
    }

    .section4 {
        min-height: 10vh;
    }

    .mobile-get-started-container{
        display: flex;
        flex-direction: row;

    }

    .mobile-image-box {
        display: block;
        position: relative;
        top: 5vh;
        left: 10vw;
        width: 30vw;
        height: 39vh;
    
    
        background: url(/static/media/Mobile-Shot.51d9b59a.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    
    
    }

    .mobile-get-started-textbox{
        margin-left: 25vw;
    }
    .mobile-get-started-textbox li{
        list-style-type: none;
    }

    .join-us-button {
   
        background: transparent linear-gradient(180deg, #EB5424 0%, #FC6D3FFC 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #F55C2B;
        border-radius: 4px;
        opacity: 1;
        text-align: center;
        font-family: 'Quicksand', sans-serif;
        font-size: 1rem;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        cursor: pointer;
        padding: 0.5rem 1rem;
        width: 10vw;
        margin-left: 2rem;
        margin-top: 1rem;
        z-index: 10000;
    
    
    }

    .mobile-get-started-textbox p {
    
        text-align: left;
        font-size: 1.4rem;
        line-height: 3rem;
        font-family: Quicksand;
        letter-spacing: 0.9px;
        color: #707070;
        opacity: 0.8;
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
        white-space:normal;
        text-overflow: ellipsis;
        vertical-align: top;
    
    }

    .mobile-get-started-textbox h1 {
    
        width: 100%;
        margin-top: 2rem;
        padding: 1rem;
        padding-left: 2rem;
        font-family: 'Quicksand', sans-serif;
        text-align: left;
        color: #F25F30;
        opacity: 0.97;
    
    }    


    

}



@media only screen and (min-width : 1000px){

    .box2 {
        background-color: rgba(139, 233, 217, 0.3);
        display: block;
        width: 25vw;
        margin: 1rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2rem;
    }
    .box2 p {
        width: 14vw;
    }


}

@media only screen and (min-width : 1300px){

    .box2 {
        background-color: rgba(139, 233, 217, 0.3);
        display: block;
        width: 25vw;
        margin: 1rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 2rem;
    }
    .box2 p {
        width: 12vw;
    }
    
    .box3 p{
        width:16vw;
    }
    .box1 p{
        width:18vw;
    }


}






/* 1263 * 857 */
